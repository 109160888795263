// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */

import { CoreActivityPageProps, PageExperience } from "@/src/page-data/classes";
import {
  ActivityEnrollableTimesModal_ActivityFragment,
  ClassDetailsQuery_LeaderFragment,
} from "@outschool/gql-frontend-generated";
import {
  addParamsToUrl,
  oneOnOneEnrollPath,
  searchPath,
  sectionEnrollPath,
  selfPacedEnrollPath,
} from "@outschool/routes";
import { useProductViewed } from "@outschool/ui-analytics";
import { useApolloClient } from "@outschool/ui-apollo";
import {
  AuthTrigger,
  useLoginFlowContext,
  useSession,
} from "@outschool/ui-auth";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  ActivityEnrollableTimesCta,
  ClassDetailsMainBody,
  DefaultBelowClassDetail,
  MobileStickyFooter,
  TrustpilotFullBleedBanner,
  useCDPEnrollOrSeeScheduleCTA,
  useMobileCDPGroupExperiment,
  useTrackClassPageImpression,
} from "@outschool/ui-components-website";
import { Space, TrackingPage } from "@outschool/ui-legacy-component-library";
import {
  useIsMobile,
  useIsMounted,
  useNavigation,
  useScrollToElement,
} from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { APP_HOSTNAME, FACEBOOK_APP_ID, OUTSCHOOL_ENV } from "../../../../Env";
import fetchOneOnOneEnrollableTimes from "../../../../lib/server-side-fetch/fetchOneOnOneEnrollableTimes";
import { isOrganizationAdminWhoCanEnroll } from "../../lib/Users";
import SimpleLayout from "../layouts/SimpleLayout";
import MessageModal from "../MessageModal.jsx";
import ChooseATimeOrClassScheduleRequestButton from "./ChooseATimeOrClassScheduleRequestButton";
import { CoreMobileBelowClassDetail } from "./CoreMobileBelowClassDetail";
import { SectionCallToAction } from "./SectionCallToActionButtons";

type CoreNotEnrolledViewProps = CoreActivityPageProps & {
  leader: ClassDetailsQuery_LeaderFragment;
  showDetails: boolean;
};

export default function CoreNotEnrolledView({
  leader,
  showDetails,
  ...props
}: CoreNotEnrolledViewProps) {
  const { activity, footerCategories } = props;
  const canEnroll = activity.canEnroll;
  const isMobile = useIsMobile();

  const router = useRouter();
  const client = useApolloClient();

  const { currentUser } = { currentUser: null };
  const isOrganizationEnrollment = isOrganizationAdminWhoCanEnroll(currentUser);

  const querySectionUid = router.query && router.query.sectionUid;
  const isPrepayMeetings =
    router.query?.isPrepayMeetings == "true"
      ? true
      : router.query?.isPrepayMeetings == "false"
      ? false
      : undefined;
  const isRestricted = Boolean(activity.isRestricted);

  const sectionsRef = React.useRef(null);
  const otherListingsRef = React.useRef(null);
  const moreLikeThisRef = React.useRef(null);
  const viewAvailabilityRef = React.useRef(null);
  const viewTheTeacherRef = React.useRef(null);

  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] =
    React.useState(false);

  const scrollToElement = useScrollToElement();
  const { enterLoginFlow } = useLoginFlowContext();

  const trackProductViewed = useProductViewed();
  React.useEffect(() => {
    trackProductViewed({
      activity,
      leader,
    });
  }, [activity, leader, trackProductViewed]);

  const onClickSeeSchedule = React.useCallback(() => {
    if (isMobile) {
      if (activity.isSelfPaced) {
        enterLoginFlow({
          authTrigger: AuthTrigger.ENROLL,
          skipLearners: true,
          newUrl: selfPacedEnrollPath(activity),
        });
      } else {
        setIsAvailabilityModalOpen(true);
      }
    } else if (sectionsRef.current) {
      scrollToElement(sectionsRef?.current);
    }
  }, [scrollToElement, isMobile, activity, enterLoginFlow]);

  const navigate = useNavigation();
  const onClickMoreLikeThis = React.useCallback(() => {
    if (isMobile && isAvailabilityModalOpen) {
      navigate(searchPath({ q: activity.title }));
    }

    otherListingsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [isMobile, isAvailabilityModalOpen, navigate, activity.title]);

  const onClickViewAvailability = React.useCallback(() => {
    if (isMobile) {
      if (activity.isSelfPaced) {
        enterLoginFlow({
          authTrigger: AuthTrigger.ENROLL,
          skipLearners: true,
          newUrl: selfPacedEnrollPath(activity),
        });
      } else {
        setIsAvailabilityModalOpen(true);
      }
    } else if (viewAvailabilityRef.current) {
      scrollToElement(viewAvailabilityRef.current);
    }
  }, [scrollToElement, isMobile, enterLoginFlow, activity]);

  const onClickViewTeacher = React.useCallback(() => {
    scrollToElement(viewTheTeacherRef?.current);
  }, [scrollToElement]);

  React.useEffect(() => {
    if (querySectionUid) {
      let path = sectionEnrollPath(activity, querySectionUid);
      enterLoginFlow({
        authTrigger: AuthTrigger.ENROLL,
        skipLearners: true,
        newUrl: path,
      });
    }
  }, [querySectionUid, enterLoginFlow, activity]);

  // tracking
  const trackClassPageImpression = useTrackClassPageImpression();
  useEffect(() => {
    trackClassPageImpression({
      activityUid: activity.uid,
      sectionUid: querySectionUid as string,
    });
  }, [activity.uid, querySectionUid, trackClassPageImpression]);

  const { esaSessionData } = useSession();

  const ctaText = useCDPEnrollOrSeeScheduleCTA({
    activity: props.activity,
  });

  const [activityWithEnrollableTimes, setActivityWithEnrollableTimes] =
    React.useState<ActivityEnrollableTimesModal_ActivityFragment | undefined>(
      undefined
    );
  if (activity.hasTeacherSchedule) {
    fetchOneOnOneEnrollableTimes({
      client,
      uid: activity.uid,
      esaSessionData,
    }).then(a => setActivityWithEnrollableTimes(a));
  }
  const loadingOneOnOneActivity = !activityWithEnrollableTimes;
  const hasOneOnOneAvailabilities =
    !!activityWithEnrollableTimes?.enrollableTimes.length;

  const onEnrollClick = React.useCallback(
    ({
      startTime,
      orgTeacherUid,
    }: {
      startTime: Date;
      orgTeacherUid?: string;
    }) => {
      let path = oneOnOneEnrollPath({
        activity,
        startTime,
        orgTeacherUid,
        isPrepayMeetings,
      });
      enterLoginFlow({
        authTrigger: AuthTrigger.ENROLL,
        skipLearners: true,
        newUrl: path,
      });
    },
    [enterLoginFlow, activity, isPrepayMeetings]
  );

  const handleRequestAnotherTime = React.useCallback(() => {
    enterLoginFlow({
      authTrigger: AuthTrigger.REQUEST_TIME,
      skipLearners: true,
      newUrl: addParamsToUrl(router.asPath, {
        openCSR: "true",
      }),
    });
  }, [router.asPath, enterLoginFlow]);

  const enableMobileCDPGroup1Exp = useMobileCDPGroupExperiment();

  const SeeScheduleButtonMemo = React.useMemo(() => {
    return activity.hasTeacherSchedule ? (
      !loadingOneOnOneActivity && (
        <ActivityEnrollableTimesCta
          onRequestAnotherTimeClick={handleRequestAnotherTime}
          onClickViewAvailability={onClickViewAvailability}
          hasOneOnOneAvailabilities={hasOneOnOneAvailabilities}
          shouldHideAlert
        />
      )
    ) : (
      <TrackedButton
        trackingName={"see_schedule_mobile_nav"}
        trackingUniqueId={activity.uid}
        onClick={onClickSeeSchedule}
        variant={enableMobileCDPGroup1Exp ? "outlined" : "contained"}
      >
        {ctaText}
      </TrackedButton>
    );
  }, [
    activity,
    onClickSeeSchedule,
    handleRequestAnotherTime,
    onClickViewAvailability,
    loadingOneOnOneActivity,
    hasOneOnOneAvailabilities,
    ctaText,
    enableMobileCDPGroup1Exp,
  ]);

  const showAvailableTimes = React.useMemo(() => {
    return (
      activity.isSelfPaced ||
      (!isMobile && !activity.isClub && !activity.hasTeacherSchedule)
    );
  }, [activity, isMobile]);

  const showDefaultClassDetail = !isMobile && showDetails && !activity.isClub;

  // this is to prevent hydration errors from trustpilot loading dynamically
  const isMounted = useIsMounted();

  return (
    <TrackingPage
      uniqueId={activity.uid}
      activity={activity}
      name="Class Details Page"
      uniqueProperties={{
        landingExperience: PageExperience[props.pageExperience],
        experimentAssignments: props.experimentAssignments,
        enrollable: canEnroll,
      }}
      useLinkTracking
    >
      {setImpressionNode => (
        <SimpleLayout
          ref={setImpressionNode}
          allowCategoriesNavbar
          footerCategories={footerCategories}
        >
          {isMobile && isMounted && (
            <TrustpilotFullBleedBanner
              isSSR={true}
              env={OUTSCHOOL_ENV}
              appHostname={APP_HOSTNAME}
            />
          )}
          {!isRestricted && isMobile && (
            <MobileStickyFooter
              activity={activity}
              hasOneOnOneAvailabilities={hasOneOnOneAvailabilities}
              isLoadingOneOnOneAvailabilities={loadingOneOnOneActivity}
              button={SeeScheduleButtonMemo}
            />
          )}
          <ClassDetailsMainBody
            // data
            activity={activity}
            activityWithEnrollableTimes={activityWithEnrollableTimes}
            leader={leader}
            canEnroll={canEnroll}
            isOrganizationEnrollment={isOrganizationEnrollment}
            isLoadingOneOnOneAvailabilities={loadingOneOnOneActivity}
            hasOneOnOneAvailabilities={hasOneOnOneAvailabilities}
            facebookAppId={FACEBOOK_APP_ID}
            showAvailableTimes={showAvailableTimes}
            // child components
            SectionCallToAction={SectionCallToAction}
            ChooseATimeOrClassScheduleRequestButton={
              ChooseATimeOrClassScheduleRequestButton
            }
            MessageModal={MessageModal}
            SeeScheduleButtonMemo={SeeScheduleButtonMemo}
            // scroll refs
            sectionsRef={sectionsRef}
            viewAvailabilityRef={viewAvailabilityRef}
            viewTheTeacherRef={viewTheTeacherRef}
            // event handlers
            onClickMoreLikeThis={onClickMoreLikeThis}
            onClickViewTeacher={onClickViewTeacher}
            onEnrollClick={onEnrollClick}
            isAvailabilityModalOpen={isAvailabilityModalOpen}
            setIsAvailabilityModalOpen={setIsAvailabilityModalOpen}
          />
          <Space y={isMobile ? 20 : 121} />
          {showDefaultClassDetail && (
            <DefaultBelowClassDetail
              otherListingsRef={otherListingsRef}
              activity={activity}
              leader={leader}
              moreLikeThisRef={moreLikeThisRef}
              isRestricted={isRestricted}
              randomPopularCategories={props.randomPopularCategories}
            />
          )}
          {isMobile && (
            <CoreMobileBelowClassDetail
              otherListingsRef={otherListingsRef}
              activity={activity}
              leader={leader}
              {...props}
            />
          )}
        </SimpleLayout>
      )}
    </TrackingPage>
  );
}
